<template>
	<v-expansion-panels>
		<v-expansion-panel>
			<v-expansion-panel-header>비밀번호 변경</v-expansion-panel-header>
			<v-expansion-panel-content>
				<validation-observer ref="observer" v-slot="{ valid }">
					<form @submit.prevent="changePassword" ref="form">
						<validation-provider
							v-slot="{ errors }"
							name="currentPassword"
							rules="required|password"
						>
							<v-text-field
								v-model="changePasswordForm.oldPassword"
								:type="isPasswordVisible ? 'text' : 'password'"
								:error-messages="errors"
								label="현재 비밀번호를 입력하세요"
								:append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="isPasswordVisible = !isPasswordVisible"
								dense
								outlined
								required
							/>
						</validation-provider>
						<validation-provider
							v-slot="{ errors }"
							name="password"
							rules="required|password"
						>
							<v-text-field
								v-model="changePasswordForm.newPassword"
								:type="isPasswordVisible ? 'text' : 'password'"
								:error-messages="errors"
								label="새로운 비밀번호를 입력하세요"
								:append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="isPasswordVisible = !isPasswordVisible"
								dense
								outlined
								required
							/>
						</validation-provider>
						<validation-provider
							v-slot="{ errors }"
							name="repassword"
							rules="required|repassword:@password"
						>
							<v-text-field
								v-model="changePasswordForm.repassword"
								:type="isPasswordVisible ? 'text' : 'password'"
								:error-messages="errors"
								label="새로운 비밀번호를 재입력하세요"
								:append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="isPasswordVisible = !isPasswordVisible"
								dense
								outlined
								required
							/>
						</validation-provider>
						<v-btn
							class="button full-width"
							type="submit"
							width="100%"
							color="primary"
							:disabled="!valid"
							:loading="changePasswordLoading"
						>
							변경하기
						</v-btn>
					</form>
				</validation-observer>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import { ref, reactive } from '@vue/composition-api'

import AuthService from '@/services/AuthService'
import { successSwal, warningSwal } from '@/plugins/swalMixin'

export default {
	props: {
		profile: {
			type: Object,
			required: true,
		},
	},
	components: {},
	setup(props) {
		const changePasswordLoading = ref(false)
		const isPasswordVisible = ref(false)

		const changePasswordForm = reactive({
			email: props.profile?.email,
			repassword: '',
			oldPassword: '',
			newPassword: '',
		})

		const changePassword = async () => {
			try {
				await AuthService.changePassword(changePasswordForm)
				successSwal('비밀번호가 변경되었습니다')
			} catch (e) {
				await warningSwal(
					e.response.data.message
						? e.response.data.message
						: '비밀번호를 변경하는데 문제가 발생했습니다',
				)
			}
		}

		return {
			changePasswordLoading,
			changePasswordForm,
			isPasswordVisible,

			changePassword,
		}
	},
}
</script>
<style lang="scss" scoped>
.full-width {
	width: 100% !important;
	max-width: 100% !important;
}

.verification-btn {
	width: 100%;
	min-height: 40px;
}
</style>
